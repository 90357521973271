import Vue from 'vue';
import Login from './Login.vue';

import "vue-mdc-adapter/dist/vue-mdc-adapter.css";

import VueMDCAdapter from 'vue-mdc-adapter';
Vue.use(VueMDCAdapter);

Vue.config.productionTip = false;

new Vue({
  render: h => h(Login),
}).$mount('#app');
