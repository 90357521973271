<template lang="pug">

  mdc-layout-app
    mdc-toolbar(slot="toolbar")
      mdc-toolbar-row
        mdc-toolbar-section(align-start)
          mdc-toolbar-menu-icon(event="toggle-drawer")
          mdc-toolbar-title Alphawing
        mdc-toolbar-section(align-end)
          mdc-toolbar-icon(icon="code" href="https://github.com/kayac/alphawing2")
          mdc-toolbar-icon(icon="contact_support" href="https://github.com/kayac/alphawing2/issues")

    mdc-drawer(slot="drawer" toggle-on="toggle-drawer")

    main
      mdc-layout-grid
        mdc-layout-cell
          mdc-dialog(v-model="openDialog" title="Login required" accept-disabled=true)
            p このアプリケーションを使用するにはGoogleアカウントでのログインが必要です。
            p(style="width: 100%; text-align: center;")
              a(:href="'/auth?url=' + referrer")
                img(src="/assets/img/btn_google_signin_dark_normal_web.png" srcset="/assets/img/btn_google_signin_dark_normal_web@2x.png 2x")
              p(style="width: 100%; text-align: center;")
                a(href="https://www.kayac.com/privacy") プライバシーポリシー

      mdc-snackbar(v-model="failReason")
</template>

<script>
export default {
  name: "Login",
  data: () => {
    return {
      openDialog: true,
      referrer: "/",
      failReason: undefined,
    };
  },
  created() {
    const url = new URL(location.href);
    const referrer = url.searchParams.get("url");
    const failed = url.searchParams.get("failed");
    if (failed === "1") {
      this.failReason = {
        message: "ログインに失敗しました。メールアドレスをご確認ください。",
        timeout: 100000,
        multiline: true,
      };
    }

    if (!referrer) {
      return;
    }

    this.referrer = referrer;
  },
};
</script>

<style lang="stylus">
html:root
  --mdc-theme-primary #004
</style>
